<!-- Copyright (C) Eruvaka Technologies Pvt Ltd - All Rights Reserved * Unauthorized copying of this file, via any medium is strictly prohibited * Proprietary and confidential * 2021 -->
<!--
File Name: pondLabTestsTab.vue
Description: This page contians UI components used in displaying lab test details in ponds section. It contains data table contianing lab records
-->
<template>
  <el-row class="pond-lab-tests-tab">
    <layout-toolbar>
      <er-date-picker
        size="mini"
        popper-class="card-chart__date-picker"
        v-model="dateRange"
        :timeZoneString="getUserTimeZoneString"
        :value-format="'yyyy-MM-dd'"
        :format="upm__getFormatDateString"
        :picker-options="pickerOptions"
        @input="handleDateRangeChange"
      ></er-date-picker>
      <div class="filler"></div>
      <template v-if="selectedView === 'GRAPH'">
        <el-select
          v-model="lab_field"
          size="mini"
        >
          <el-option
            v-for="{ value, label } in getSortedLabsFileds"
            :key="value"
            :value="value"
            :label="label"
          ></el-option>
        </el-select>
      </template>
      <!-- <er-date-picker
        size="mini"
        popper-class="card-chart__date-picker"
        v-model="dateRange"
        :timeZoneString="getUserTimeZoneString"
        value-format="yyyy-MM-dd"
        :format="upm__getFormatDateString"
      ></er-date-picker> -->
      <el-radio-group
        class="radio-btn-group"
        v-model="selectedView"
        size="mini"
        @change="handleChangeInView"
      >
        <el-radio-button label="GRAPH">
          <svg
            class="icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 30.579 22.934"
          >
            <path
              d="M19.877 15.29h2.293a.822.822 0 00.764-.764V6.5a.822.822 0 00-.764-.764h-2.293a.822.822 0 00-.764.764v8.027a.822.822 0 00.764.763zm5.734 0H27.9a.822.822 0 00.764-.764V.764A.822.822 0 0027.9 0h-2.29a.822.822 0 00-.764.764v13.761a.822.822 0 00.764.765zm-17.2 0H10.7a.822.822 0 00.764-.764v-4.2a.822.822 0 00-.764-.764H8.409a.822.822 0 00-.764.764v4.2a.822.822 0 00.764.764zm5.734 0h2.293a.822.822 0 00.764-.764V2.676a.822.822 0 00-.764-.764h-2.295a.822.822 0 00-.764.764v11.849a.822.822 0 00.764.765zm15.481 3.822H3.822V.956A.955.955 0 002.867 0H.956A.955.955 0 000 .956v20.067a1.911 1.911 0 001.911 1.911h27.713a.955.955 0 00.956-.956v-1.91a.955.955 0 00-.956-.956z"
            />
          </svg>
        </el-radio-button>
        <el-radio-button label="TABLE">
          <svg
            class="icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20.347 17.804"
          >
            <path
              d="M18.439 0H1.908A1.908 1.908 0 000 1.908V15.9a1.908 1.908 0 001.908 1.9h16.531a1.908 1.908 0 001.908-1.9V1.908A1.908 1.908 0 0018.439 0zM8.9 15.26H2.543v-3.815H8.9zm0-6.358H2.543V5.087H8.9zm8.9 6.358h-6.355v-3.815H17.8zm0-6.358h-6.355V5.087H17.8z"
            />
          </svg>
        </el-radio-button>
      </el-radio-group>
    </layout-toolbar>
    <el-row class="pond-lab-tests-tab-body" v-if="selectedView === 'TABLE'">
      <el-col>
        <er-card v-loading="loading" element-loading-background="white">
          <div class="loading_class" v-if="loading"></div>
          <er-data-tables
            ref="labTestsTable"
            :el-table-props="tableProps"
            v-show="!loading"
            :tableData="tableData"
            :key="$i18n.locale"
            v-loading="loading"
            :columns="tableColumns"
            :pagination-props="$constants.table['pagination-props']"
            :page-size="$constants.table['page-size']"
            element-loading-background="white"
            type="white-header-table"
            unique-id="harvest-yield"
            :action-column="false"
          >
          <template v-slot:co3_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:hco3_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:do_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:no2_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:ammonia_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:iron_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:magnesium_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:calcium_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:potassium_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
        <template v-slot:ntu_header="{ header }">
          <div class="table-col-header-container">
            <el-tooltip
              popperClass="lab-test-table-el-tooltip__popper"
              :content="header.sub_label"
              placement="top"
              effect="light"
            >
              <p>{{ header.label }}</p>
            </el-tooltip>
          </div>
        </template>
            <template v-slot:date="{ row }">{{
              getEventDate(row.data.date)
            }}</template>
            <template v-slot:green_colonies="{ row }">{{
              ftm__digitPrecision(row.data.green_colonies, 2)
            }}</template>
            <template v-slot:yellow_colonies="{ row }">{{
              ftm__digitPrecision(row.data.yellow_colonies, 2)
            }}</template>
            <template v-slot:no2="{ row }">{{
              ftm__digitPrecision(row.data.no2, 2)
            }}</template>
            <template v-slot:="{ row }">{{
              ftm__digitPrecision(row.data.yellow_colonies, 2)
            }}</template>
            <template v-slot:calcium="{ row }">{{
              ftm__digitPrecision(row.data.calcium, 2)
            }}</template>
            <template v-slot:magnesium="{ row }">{{
              ftm__digitPrecision(row.data.magnesium, 2)
            }}</template>
            <template v-slot:potassium="{ row }">{{
              ftm__digitPrecision(row.data.potassium, 2)
            }}</template>
            <template v-slot:ntu="{ row }">{{
              ftm__digitPrecision(row.data.ntu, 2)
            }}</template>
            <template v-slot:ph="{ row }">{{
              ftm__digitPrecision(row.data.ph, 2)
            }}</template>
            <template v-slot:salinity="{ row }">{{
              ftm__digitPrecision(row.data.salinity, 2)
            }}</template>
            <template v-slot:do="{ row }">{{
              ftm__digitPrecision(row.data.do, 2)
            }}</template>
            <template v-slot:co3="{ row }">{{
              ftm__digitPrecision(row.data.co3, 2)
            }}</template>
            <template v-slot:hco3="{ row }">{{
              ftm__digitPrecision(row.data.hco3, 2)
            }}</template>
            <template v-slot:alkallnity_total="{ row }">{{
              ftm__digitPrecision(row.data.alkallnity_total, 2)
            }}</template>
            <template v-slot:hardness="{ row }">{{
              ftm__digitPrecision(row.data.hardness, 2)
            }}</template>
            <template v-slot:ammonia="{ row }">{{
              ftm__digitPrecision(row.data.ammonia, 2)
            }}</template>
            <template v-slot:iron="{ row }">{{
              ftm__digitPrecision(row.data.iron, 2)
            }}</template>
            <template v-slot:actions="{ row }">
              <el-row type="flex">
                <er-button
                  type="text"
                  size="mini"
                  btnType="edit"
                  :showLabel="true"
                  :showIcon="true"
                  @click="handleEditRecord(row.data)"
                  :disabled="disabledBtn"
                ></er-button>
                <el-divider
                  direction="vertical"
                  style="height: 100%"
                ></el-divider>
                <er-button
                  type="text"
                  size="mini"
                  btnType="delete"
                  :showLabel="true"
                  :showIcon="true"
                  @click="handleDeleteRecord(row.data)"
                  :disabled="disabledBtn"
                ></er-button>
              </el-row>
            </template>
          </er-data-tables>
        </er-card>
      </el-col>
    </el-row>
    <el-row class="pond-lab-tests-tab-body" v-else element-loading-background="white">
          <pondLabTestsChart :labField="labFieldData"></pondLabTestsChart>
    </el-row>
    <er-dialog
      :visible.sync="dialogVisible"
      width="25%"
      :close-on-click-modal="false"
      class="pond-input-tab"
      custom-class="pondLabTest_dialog"
      :title="`${$t('Comn_update')} ${$t('LabTst_lab_test')}`"
    >
      <el-form size="small" :model="editLabRec" :hide-required-asterisk="true">
        <!-- ph, -->
        <el-form-item :label="$t('Comn_ph')">
          <el-col :span="22">
            <el-input-number
              v-model="editLabRec.ph"
              :controls="false"
              size="mini"
              :error="ekmm__castErrorKeyToLang(ehm__errMessagesObject.ph)"
            ></el-input-number>
          </el-col>
        </el-form-item>
        <!-- salinity, -->
        <el-form-item :label="$t('Comn_salinity_ppt')">
          <el-col :span="22">
            <el-input-number
              v-model="editLabRec.salinity"
              :controls="false"
              :min="0"
              size="mini"
            ></el-input-number>
          </el-col>
        </el-form-item>
        <!-- do, -->
        <el-form-item :label="$t('Comn_do')">
          <el-col :span="22">
            <el-input-number
              v-model="editLabRec.do"
              :controls="false"
              :min="0"
              size="mini"
            ></el-input-number>
          </el-col>
        </el-form-item>
        <!-- co3, -->
        <el-form-item :label="$t('Comn_co3')">
          <el-col :span="22">
            <el-input-number
              v-model="editLabRec.co3"
              :controls="false"
              size="mini"
              @change="onCo3Change(editLabRec)"
            ></el-input-number>
          </el-col>
        </el-form-item>
        <!-- hco3, -->
        <el-form-item :label="$t('Comn_hco3')">
          <el-col :span="22">
            <el-input-number
              v-model="editLabRec.hco3"
              :controls="false"
              :min="0"
              size="mini"
              @change="onHco3Change(editLabRec)"
            ></el-input-number>
          </el-col>
        </el-form-item>
        <!-- alkallnity_total,  -->
        <el-form-item :label="$t('Comn_alkallnity')">
          <el-col :span="22">
            <el-input-number
              v-model="editLabRec.alkallnity_total"
              :controls="false"
              :disabled="false"
              size="mini"
            ></el-input-number>
          </el-col>
        </el-form-item>
        <!-- hardness, -->
        <el-form-item :label="$t('Comn_hardness_ppm')">
          <el-col :span="22">
            <el-input-number
              v-model="editLabRec.hardness"
              :controls="false"
              :min="0"
              size="mini"
            ></el-input-number>
          </el-col>
        </el-form-item>
        <!-- ammonia, -->
        <el-form-item :label="$t('Comn_ammonia_nh4')">
          <el-col :span="22">
            <el-input-number
              v-model="editLabRec.ammonia"
              :controls="false"
              :min="0"
              size="mini"
            ></el-input-number>
          </el-col>
        </el-form-item>
        <!-- iron, -->
        <el-form-item :label="$t('Comn_iron')">
          <el-col :span="22">
            <el-input-number
              v-model="editLabRec.iron"
              :controls="false"
              :min="0"
              size="mini"
            ></el-input-number>
          </el-col>
        </el-form-item>
        <!-- green_colonies, -->
        <el-form-item :label="$t('Comn_green_colonies')">
          <el-col :span="22">
            <el-input-number
              v-model="editLabRec.green_colonies"
              :controls="false"
              :min="0"
              size="mini"
            ></el-input-number>
          </el-col>
        </el-form-item>
        <!-- yellow_colonies -->
        <el-form-item :label="$t('Comn_yellow_colonies')">
          <el-col :span="22">
            <el-input-number
              v-model="editLabRec.yellow_colonies"
              :controls="false"
              :min="0"
              size="mini"
            ></el-input-number>
          </el-col>
        </el-form-item>
        <el-form-item :label="$t('Comn_no2')">
          <el-col :span="22">
            <el-input-number
              v-model="editLabRec.no2"
              :controls="false"
              :min="0"
              size="mini"
            ></el-input-number>
          </el-col>
        </el-form-item>
        <el-form-item :label="$t('Comn_Calcium')">
          <el-col :span="22">
            <el-input-number
              v-model="editLabRec.calcium"
              :controls="false"
              :min="0"
              size="mini"
            ></el-input-number>
          </el-col>
        </el-form-item>
        <el-form-item :label="$t('Comn_Magnesium')">
          <el-col :span="22">
            <el-input-number
              v-model="editLabRec.magnesium"
              :controls="false"
              :min="0"
              size="mini"
            ></el-input-number>
          </el-col>
        </el-form-item>
        <el-form-item :label="$t('Comn_Potassium')">
          <el-col :span="22">
            <el-input-number
              v-model="editLabRec.potassium"
              :controls="false"
              :min="0"
              size="mini"
            ></el-input-number>
          </el-col>
        </el-form-item>
        <el-form-item :label="$t('Comn_NTU')">
          <el-col :span="22">
            <el-input-number
              v-model="editLabRec.ntu"
              :controls="false"
              :min="0"
              size="mini"
            ></el-input-number>
          </el-col>
        </el-form-item>
      </el-form>
      <layout-toolbar slot="footer" justify="center">
        <er-button
          size="mini"
          btnType="save"
          :showLabel="true"
          :showIcon="true"
          :loading="loading"
          @click="decideActionAndPrepPayload"
          :disabled="isReadOnly"
        ></er-button>
        <er-button
          size="mini"
          btnType="cancel"
          :showLabel="true"
          @click="closeDialogBox"
          :disabled="isReadOnly"
        ></er-button>
      </layout-toolbar>
    </er-dialog>
  </el-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import filtersMixin from "@/mixins/filtersMixin";
import errorHandlerMixin from "@/mixins/errorHandlerMixin";
import errorKeyMapMixin from "@/mixins/errorKeyMapMixin";
import datesHandlerMixin from "@/mixins/datesHandlerMixin";
import notificationMixin from "@/mixins/notificationMixin";
import pondLabTestsChart from "./pondLabTestsChart.vue";

export default {
  name: "pondLabTestsTab",
  props: ["tabData"],

  mixins: [
    errorHandlerMixin,
    errorKeyMapMixin,
    notificationMixin,
    filtersMixin,
    datesHandlerMixin
  ],
  components: { pondLabTestsChart },
  data: function () {
    return {
      pickerOptions: {
        disabledDate: (time) => {
          const startDate = new Date(this.getCurrentCulture.start_date);
          const today = new Date(); // Get the current date (not time)

          // Set the time components of both dates to 0 for accurate comparison
          startDate.setHours(0, 0, 0, 0);
          today.setHours(0, 0, 0, 0);

          return (
            time.getTime() < startDate.getTime() ||
            time.getTime() > today.getTime()
          );
        }
      },
      dateRange: [],
      loading: true,
      dialogVisible: false,
      tableProps: {
        size: "small",
        height: "calc(100vh - var(--height-gutter))"
      },
      editLabRec: {
        ph: undefined,
        salinity: undefined,
        do: undefined,
        co3: undefined,
        hco3: undefined,
        alkallnity_total: undefined,
        hardness: undefined,
        ammonia: undefined,
        iron: undefined,
        green_colonies: undefined,
        yellow_colonies: undefined
      },
      newErrObject: {
        ph: ""
      },
      ehm__errMessagesObject: {
        ph: ""
      },
      ekmm__backndfieldToFieldNameMap: {
        ph: "Resource_Lab_Name"
      },
      ekmm__backndfieldToInvalidMap: {
        ph: {
          FIELD_REQUIRED: "Comn_name"
        }
      },
      selectedView: "TABLE",
      lab_field: "do"
    };
  },

  computed: {
    ...mapGetters("pondDetails", {
      getPondIdPondMap: "getPondIdPondMap",
      getCurrentCulture: "getCurrentCulture",
      pondId: "getSelectedPondId",
      getSelectedPondLabTests: "getSelectedPondLabTests"
    }),
    ...mapGetters("user", {
      getUserLocations: "getUserLocations",
      getCurrUserLocation: "getCurrUserLocation",
      getUserTimeZoneString: "getUserTimeZoneString",
      isReadOnly: "isReadOnly"
    }),
    labFieldData() {
      return this.getSortedLabsFileds.find(item => item.value === this.lab_field)
    },
    getSortedLabsFileds() {
      return [
        {
          label: this.$t("Comn_do"),
          value: "do"
        },
        {
          label: this.$t("Comn_ph"),
          value: "ph"
        },
        {
          label: this.$t("Comn_salinity_ppt"),
          value: "salinity"
        },
        {
          label: "NH3 (ppm)",
          value: "ammonia"
        },
        {
          label: this.$t("Comn_hardness_ppm"),
          value: "hardness"
        },
        {
          label: "Fe (ppm)",
          value: "iron"
        },
        {
          label: this.$t("Comn_co3"),
          value: "co3"
        },
        {
          label: this.$t("Comn_hco3"),
          value: "hco3"
        },
        {
          label: this.$t("Comn_alkallnity"),
          value: "alkallnity_total"
        },
        {
          label: this.$t("Comn_green_colonies"),
          value: "green_colonies"
        },
        {
          label: this.$t("Comn_yellow_colonies"),
          value: "yellow_colonies"
        },
        {
          value: "no2",
          label: this.$t("Comn_no2")
        },
        {
          value: "calcium",
          label: "Ca (ppm)"
        },
        {
          value: "magnesium",
          label: "Mg (ppm)"
        },
        {
          value: "potassium",
          label: "K (ppm)"
        },
        {
          value: "ntu",
          label: "NTU"
        }]
    },
    getLocaleObj: function() {
      return { locale: this.$i18n.locale };
    },
    PondsObj: function() {
      return this.getPondIdPondMap;
    },
    selectedPondDetails() {
      return this.PondsObj[this.pondId];
    },
    tableData() {
      return this.$lodash.cloneDeep(this.getSelectedPondLabTests).map((x) => {
        x.dateStr = this.dhm__formatByTZOnUTCWithLocale(
          x.date,
          this.$commonUtils.DATE_OBJ_DATE_FORMAT_WITH_YEAR
        );
        return x;
      });
    },
    tableColumns() {
      return {
        date: {
          label: this.$t("Comn_test_date"),
          prop: "date",
          minWidth: 150
        },
        lab_name: {
          label: this.$t("Resource_lab_name"),
          prop: "lab_id.title",
          minWidth: 120
        },
        do: {
          label: this.$t("Comn_do"),
          sub_label: this.$t("PM_dissolved_oxygen"),
          prop: "do"
        },
        ph: {
          label: this.$t("Comn_ph"),
          prop: "ph"
        },
        salinity: {
          label: this.$t("Comn_salinity_ppt"),
          prop: "salinity",
          minWidth: 110
        },
        ammonia: {
          label: "NH3 (ppm)",
          sub_label: this.$t("Comn_ammonia_ppm"),
          prop: "ammonia",
          minWidth: 110
        },
        hardness: {
          label: this.$t("Comn_hardness_ppm"),
          prop: "hardness",
          minWidth: 110
        },
        iron: {
          label: "Fe (ppm)",
          sub_label: this.$t("Comn_iron_ppm"),
          prop: "iron"
        },
        co3: {
          label: this.$t("Comn_co3"),
          sub_label: this.$t("PM_Carbonates"),
          prop: "co3"
        },
        hco3: {
          label: this.$t("Comn_hco3"),
          sub_label: this.$t("PM_Bicarbonates"),
          prop: "hco3"
        },
        alkallnity_total: {
          label: this.$t("Comn_alkallnity"),
          prop: "alkallnity_total"
        },
        green_colonies: {
          label: this.$t("Comn_green_colonies"),
          prop: "green_colonies",
          minWidth: 110
        },
        yellow_colonies: {
          label: this.$t("Comn_yellow_colonies"),
          prop: "yellow_colonies",
          minWidth: 110
        },
        no2: {
          prop: "no2",
          label: this.$t("Comn_no2"),
          sub_label: this.$t("Comn_Nitrogen_Dioxide"),
          minWidth: 130
        },
        calcium: {
          prop: "calcium",
          label: "Ca (ppm)",
          sub_label: this.$t("Comn_Calcium"),
          minWidth: 130
        },
        magnesium: {
          prop: "magnesium",
          label: "Mg (ppm)",
          sub_label: this.$t("Comn_Magnesium"),
          minWidth: 130
        },
        potassium: {
          prop: "potassium",
          minWidth: 120,
          label: "K (ppm)",
          sub_label: this.$t("Comn_Potassium"),
        },
        ntu: {
          prop: "ntu",
          minWidth: 110,
          label: "NTU",
          sub_label: this.$t("Comn_NTU"),
        },
        actions: {
          label: this.$t("Comn_actions"),
          prop: "actions",
          minWidth: 145
        }
      };
    },
    disabledBtn() {
      return this.isReadOnly || !this.getCurrentCulture.is_running;
    }
  },
  async mounted() {
    const endDate = this.dhm__formatByTZOnUTCISOWithLocale(
        this.getCurrentCulture.end_date,
        "yyyy-MM-dd"
      );
      const startDate = this.dhm__formatByTZOnUTCISOWithLocale(
        this.getCurrentCulture.start_date,
        "yyyy-MM-dd"
      );
      this.dateRange = [startDate, endDate];
    await this.initComponent();
    this.$nextTick(() => {
      if (this.$refs.labTestsTable) {
        this.$refs.labTestsTable.$refs.dataTables.$refs.elTable.doLayout();
      }
    });
  },
  methods: {
    ...mapActions("pondDetails", {
      fetchLabTestsByPondId: "fetchLabTestsByPondId"
    }),
    ...mapActions("labTests", {
      addLabTests: "addLabTests",
      deleteLabTest: "deleteLabTest"
    }),
    ...mapActions("user", {
      mixPanelEventGenerator: "mixPanelEventGenerator"
    }),
    // onCo3Change(data) {
    //   // update the record for alklinity total
    //   data.alkallnity_total = data?.co3 + data?.hco3;
    // },
    // onHco3Change(data) {
    //   data.alkallnity_total = data?.co3 + data?.hco3;
    // },
    async handleDateRangeChange(newDateRange) {
      // TODO: Handle date range change
      this.dateRange = [newDateRange[0], newDateRange[1]];
      const params = {
          from_date: newDateRange[0] + "T00:00:00.000Z",
          to_date: newDateRange[1] + "T23:59:59.999Z",
          get_all: true
        }
      await this.fetchLabTestsByPondId(params);
    },
    onCo3Change(data) {
      // Update alkalinity_total with a default value (10) if only co3 is defined
      if (typeof data.co3 === "number" && typeof data.hco3 !== "number") {
        data.alkallnity_total = data.co3; // Use 10 as default for hco3
      } else if (
        typeof data.co3 !== "number" &&
        typeof data.hco3 === "number"
      ) {
        data.alkallnity_total = data.hco3; // Use 10 as default for hco3
      } else if (
        typeof data.co3 === "number" &&
        typeof data.hco3 === "number"
      ) {
        data.alkallnity_total = (data.co3 + data.hco3).toFixed(2); // Normal addition if both are numbers
      } else {
        // Set alkalinity_total to undefined if neither is a number
        data.alkallnity_total = undefined;
      }
    },

    onHco3Change(data) {
      // Update alkalinity_total with a default value (10) if only hco3 is defined
      if (typeof data.co3 !== "number" && typeof data.hco3 === "number") {
        data.alkallnity_total = data.hco3; // Use 10 as default for co3
      } else if (
        typeof data.co3 === "number" &&
        typeof data.hco3 !== "number"
      ) {
        data.alkallnity_total = data.co3; // Use 10 as default for co3
      } else if (
        typeof data.co3 === "number" &&
        typeof data.hco3 === "number"
      ) {
        data.alkallnity_total = (data.co3 + data.hco3).toFixed(2); // Normal addition if both are numbers
      } else {
        // Set alkalinity_total to undefined if neither is a number
        data.alkallnity_total = undefined;
      }
    },
    handleChangeInView(value) {
      this.selectedView = value;
    },
    getEventDate(date) {
      return this.dhm__formatByTZOnUTCWithLocale(
        date,
        // "yyyy-MMM-dd HH:mm"
        this.upm__getFormatDateAndTimeString
      );
    },
    async initComponent() {
      try {
        await this.fetchLabTestsByPondId();
        this.loading = false;
      } catch (error) {
        console.log(error);
      } finally {
        this.$nextTick(() => {
          if (this.$refs.labTestsTable) {
            this.$refs.labTestsTable.$refs.dataTables.$refs.elTable.doLayout();
          }
        });
      }
    },
    async handleEditRecord(editLabRec) {
      try {
        this.$gblUAMCanUserEdit(this.tabData);
        this.ehm__errMessagesObject = this.$lodash.cloneDeep(this.newErrObject);
        this.dialogVisible = true;
        // this.editLabRec = JSON.parse(JSON.stringify(editLabRec));
        console.log("editLabRec", editLabRec);
        this.editLabRec = this.$lodash.cloneDeep(editLabRec);
        if (this.editLabRec.alkallnity_total === 0) {
          this.editLabRec.alkallnity_total = undefined;
        }
        // this.editLabRec.alkallnity_total =
        // this.editLabRec.map((item) => {
        //   if (item.alkallnity_total && item.alkallnity_total === 0) {
        //     item.alkallnity_total = undefined;
        //   }
        // })
        // this.editLabRec = editLabRec;
      } catch (error) {
        this.ehm__errorMessages(error, true);
      }
    },
    async handleDeleteRecord(record) {
      try {
        this.$gblUAMCanUserDelete(this.tabData);
        await this.nofm__deleteConfirmation(
          this.$t("LabTst_lab_test"),
          this.selectedPondDetails.title
        );
        this.loading = true;
        await this.deleteLabTest(record._id);
        this.$notify({
          title: this.$t("Comn_record_delete_success_msg"),
          message: this.$t("Lab_tests_deleted_success"),
          duration: 5000,
          type: "success"
        });
        this.mixPanelEventGenerator({
          eventName: "Ponds - Lab Tests - table Data - Delete"
        });
        await this.initComponent();
      } catch (err) {
        if (err !== "cancel") {
          this.ehm__errorMessages(err, true);
        }
      } finally {
        this.loading = false;
      }
    },
    // getValidationError(record) {
    //   console.log("record", record);
    //   if (!(record.ph >= 1) || !(record.ph <= 14)) {
    //     return {
    //       message: `pH ${this.$t("Comn_value_should_be")} 1 to 14`
    //     };
    //   } else if (!(record.salinity >= 1) || !(record.salinity <= 14)) {
    //     return {
    //       message: `${this.$t("Comn_salinity")} ${this.$t(
    //         "Comn_value_should_be"
    //       )} 1 to 100`
    //     };
    //   } else if (!(record.do >= 1) || !(record.do <= 100)) {
    //     return {
    //       message: `DO ${this.$t("Comn_value_should_be")} 1 to 100`
    //     };
    //   } else if (!(record.ph >= 1) || !(record.ph <= 14)) {
    //     return {
    //       message: `pH ${this.$t("Comn_value_should_be")} 1 to 14`
    //     };
    //   } else if (
    //     !(record.alkallnity_total >= 1) &&
    //     !(record.alkallnity_total <= 100)
    //   ) {
    //     return {
    //       message: `Alkallnity ${this.$t("Comn_value_should_be")} 1 to 100`
    //     };
    //   } else if (!(record.salinity >= 1) || !(record.salinity <= 100)) {
    //     return {
    //       message: `Salinity ${this.$t("Comn_value_should_be")} 1 to 100`
    //     };
    //   } else if (!(record.hardness >= 1) || !(record.hardness <= 10000)) {
    //     return {
    //       message: `Hardness ${this.$t("Comn_value_should_be")} 1 to 10000`
    //     };
    //   } else if (!(record.ammonia >= 1) || !(record.ammonia <= 100)) {
    //     return {
    //       message: `Ammonia ${this.$t("Comn_value_should_be")} 1 to 100`
    //     };
    //   } else if (!(record.iron >= 1) || !(record.iron <= 100)) {
    //     return {
    //       message: `Iron ${this.$t("Comn_value_should_be")} 1 to 100`
    //     };
    //   } else if (!(record.co3 >= 1) || !(record.co3 <= 100)) {
    //     return {
    //       message: `CO3 ${this.$t("Comn_value_should_be")} 1 to 100`
    //     };
    //   } else if (!(record.hco3 >= 1) || !(record.hco3 <= 100)) {
    //     return {
    //       message: `HCO3 ${this.$t("Comn_value_should_be")} 1 to 100`
    //     };
    //   } else if (
    //     !(record.green_colonies >= 1) ||
    //     !(record.green_colonies <= 1000)
    //   ) {
    //     return {
    //       message: `Green Colonies ${this.$t("Comn_value_should_be")} 1 to 1000`
    //     };
    //   } else if (
    //     !(record.yellow_colonies >= 1) ||
    //     !(record.yellow_colonies <= 1000)
    //   ) {
    //     return {
    //       message: `Yellow Colonies ${this.$t(
    //         "Comn_value_should_be"
    //       )} 1 to 1000`
    //     };
    //   } else {
    //     return {};
    //   }
    // },
    getValidationError(record) {
      // Early return to improve performance
      if (Object.values(record).every((field) => field === undefined)) {
        return {}; // No validation needed if all fields are empty
      }

      // Consolidated validation using a loop (avoid duplicate checks)
      const fieldsToCheck = [
        { name: "ph", lang: "Comn_ph", range: [0.1, 14] },
        { name: "salinity", lang: "Comn_salinity_ppt", range: [0.1, 60] },
        { name: "do", lang: "PM_dissolved_oxygen", range: [0.1, 100] },
        { name: "alkallnity_total", lang: "Comn_alkallnity", range: [0.1, Infinity] },
        { name: "hardness", lang: "Comn_hardness_ppm", range: [0.1, 10000] },
        { name: "ammonia", lang: "Comn_ammonia_ppm", range: [0.1, 100] },
        { name: "iron", lang: "Comn_iron_ppm", range: [0.1, 100] },
        { name: "co3", lang: "Comn_co3", range: [0.1, Infinity] },
        { name: "hco3", lang: "Comn_hco3", range: [0.1, Infinity] },
        { name: "green_colonies", lang: "Comn_green_colonies", range: [0.1, 1000] },
        { name: "yellow_colonies", lang: "Comn_yellow_colonies", range: [0.1, 1000] },
        { name: "potassium", lang: "Comn_Potassium", range: [0.1, 1000] },
        { name: "magnesium", lang: "Comn_Magnesium", range: [0.1, 1000] },
        { name: "calcium", lang: "Comn_Calcium", range: [0.1, 1000] },
        { name: "no2", lang: "Comn_no2", range: [0.1, 1000] },
        { name: "ntu", lang: "Comn_NTU", range: [0.1, 1000] }
        // ... add other fields with their ranges
      ];

      for (const field of fieldsToCheck) {
        const value = record[field.name];
        // if (
        //   field.name !== "ph"
        // ) {
        if (
          field.name === "ph" &&
          value !== undefined &&
          (value > field.range[1])
        ) {
          return {
            message: `${field.name === 'alkallnity_total' ? this.$t("alkalinity") : this.$t(field.lang)} ${this.$t(
              "Comn_value_should_be"
            )} ${field.range[0]} to ${field.range[1]}`
          };
        }
        if (value !== undefined && value <= 0) {
            return {
              message: `${field.name === 'alkallnity_total' ? this.$t("alkalinity") : this.$t(field.lang)} ${this.$t(
                "Comn_value_should_be"
              )} ${this.$t("Comn_greater_than")} 0`
            };
          }
      }

      // No validation errors found
      return {};
    },
    // validatePayload(labRec) {
    //   if (this.getValidationError(labRec).message) {
    //     throw {
    //       type: "FAIL_TO_SAVE",
    //       errors: [this.getValidationError(labRec)]
    //     };
    //   }
    //   // const validRecords = payloadData.labRecordsLocal.filter((record) => {
    //   //   // Check for at least one non-required field with a value
    //   //   return Object.values(otherFields).some((field) => field !== undefined);
    //   // });

    //   const { date, lab_id, pond_id, ...otherFields } = labRec;
    //   const validRecords = Object.values(otherFields).some(
    //     (field) => field !== undefined
    //   );

    //   if (validRecords.length === 0) {
    //     // Show error message: No valid records to save
    //     this.ehm__errorFailToSave([{ message: this.$t("No Valid data") }]);
    //     // return;
    //   }
    // },
    validatePayload(labRec) {
      const {
        date,
        lab_id,
        pond_id,
        updatedAt,
        user_id,
        dateStr,
        createdAt,
        _id,
        ...otherFields
      } = labRec;
      const hasValues = Object.values(otherFields).some(
        (field) => field !== undefined
      );

      if (!hasValues) {
        // Show error message: No valid records to save
        this.ehm__errorFailToSave([{ message: this.$t("No Valid data") }]);
        throw {
          type: "FAIL_TO_SAVE",
          errors: [{ message: this.$t("No Valid data") }]
        };
      }

      const validationError = this.getValidationError(labRec);
      if (validationError.message) {
        throw {
          type: "FAIL_TO_SAVE",
          errors: [validationError]
        };
      }
    },
    async decideActionAndPrepPayload() {
      try {
        this.validatePayload(this.editLabRec);
        this.loading = true;
        this.editLabRec.lab_id = this.editLabRec.lab_id._id;
        // this.editLabRec.alkallnity_total =
        //   !this.editLabRec.co3 &&
        //   this.editLabRec.co3 !== 0 &&
        //   !this.editLabRec.hco3 &&
        //   this.editLabRec.hco3 !== 0
        //     ? 0
        //     : parseInt(this.editLabRec.co3) + parseInt(this.editLabRec.hco3);
        // if (!this.editLabRec.alkallnity_total) {
        //   if (!this.editLabRec.co3 && this.editLabRec.hco3) {
        //     this.editLabRec.alkallnity_total = this.editLabRec.hco3;
        //   } else if (!this.editLabRec.hco3 && this.editLabRec.co3) {
        //     this.editLabRec.alkallnity_total = this.editLabRec.co3;
        //   } else {
        //     this.editLabRec.alkallnity_total = 0;
        //   }
        // }
        const payload = {
          lab_tests: [this.editLabRec]
        };
        await this.addLabTests(payload);
        this.$notify({
          title: this.$t("Usrs_success_msg"),
          message: this.$t("Lab_tests_created_success"),
          duration: 5000,
          type: "success"
        });
        this.mixPanelEventGenerator({
          eventName: "Ponds - Lab Tests - table Data - Edit"
        });
        this.closeDialogBox();
        await this.initComponent();
      } catch (err) {
        const errors = [];
        if (err.errors && err.errors.length > 0) {
          this.ehm__errorMessages(err, true);
        } else if (err.response && err.response.data.errors != null) {
          err.response.data.errors.details.forEach((el, index) => {
            errors.push({ message: el.message });
          });
          this.ehm__errorFailToSave(errors);
        } else if (err.response && err.response.data.message) {
          errors.push({ message: err.response.data.message });
          this.ehm__errorFailToSave(errors);
        } else {
          this.ehm__errorMessages(err, true);
        }
        await this.initComponent();
      } finally {
        this.loading = false;
      }
    },
    closeDialogBox() {
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss">
.pond-lab-tests-tab {
  .pond-lab-tests-tab-body {
    margin-top: 8px;
  }
  // margin-top: -32px;
  @include responsiveProperty(--height-gutter, 235px, 248px, 260px);
  .el-form-item__label {
    width: 130px;
  }
  .loading_class {
    @include responsiveProperty(height, 67.5vh, 72vh, 77vh);
    width: 100%;
    padding: 0px;
    margin: 0px;
    background-color: white;
    text-align: center;
  }
  .radio-btn-group {
    display: inline-flex;
    align-items: center;
    line-height: 1.5;
    .el-radio:not(:last-child) {
      margin-right: 10px;
    }
    .el-radio-button {
      .icon {
        @include responsiveProperty(width, 1em, 1.3em, 1.5em);
        @include responsiveProperty(height, 1em, 1.3em, 1.5em);

        fill: black;
      }
      &.is-active {
        .icon path {
          fill: white;
        }
        .el-radio-button__inner {
          background-color: #0a2463 !important;
          border-color: #0a2463 !important;
          box-shadow: -1px 0 0 0 #0a2463;
          -webkit-box-shadow: -1px 0 0 0 #0a2463;
        }
      }
      .el-radio-button__inner {
        padding: 5px 11px;
        .el-radio__inner {
          width: 15px;
          height: 15px;
          &:hover {
            border-color: #333333;
          }
        }
      }
    }
  }
}
.pondLabTest_dialog {
  border-radius: 10px;
}
</style>
